
const initState = {
    logged: false,
    course: '',
    token: '',
};


const AuthReducer = (state = initState, action) => {

    // dispatch({type:'login', token:'aaa', course:'bbb})
    if (action.type === 'login') {
        sessionStorage.setItem("TOKEN", action.token);
        sessionStorage.setItem("COURSE", action.course);
        return {
            ...state,
            logged: true,
            course: action.course,
            token: action.token
        }
    }

    // dispatch({type:'logout'})
    else if (action.type === 'logout') {
        sessionStorage.removeItem("TOKEN");
        sessionStorage.removeItem("COURSE");
        return {
            ...state,
            logged: false,
            course: '',
            token: '',
        }
    }

    else {
        const token = sessionStorage.getItem("TOKEN");
        const course = sessionStorage.getItem("COURSE");
        if (token !== null && course !== null) {
            state.token = token;
            state.course = course;
            state.logged = true;
        }
        return state;
    }

};


export default AuthReducer;
