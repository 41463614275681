
import axios from 'axios';
import { Button } from 'primereact/button';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {ButtonGroup} from 'primereact/buttongroup';
const { ace } = window;

const NoticePage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { token } = useSelector((state) => state.AuthReducer);

    useEffect(() => {
        handleInit();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const handleInit = async () => {
        const url = `/api/member/file_notice.json`;
        const headers = { "Content-Type": "application/json", "auth": token };
        const { data } = await axios.get(url, { headers });
        // console.log(data);

        if (data.status === 200) {
            var editor1 = ace.edit("editor1", {
                theme: "ace/theme/eclipse",
                mode: "ace/mode/java",
                maxLines: Infinity,
                wrap: true,
                /* fontFamily : 'Nanum Gothic', */
                fontSize: "16px",
                autoScrollEditorIntoView: true,
                showPrintMargin: false,
                keyboardHandler: "ace/keyboard/vscode",
            });

            editor1.setValue(data.doc.filedata);
            editor1.gotoLine(0, 0);

            editor1.setReadOnly(true);
            editor1.setHighlightActiveLine(false);
            editor1.setHighlightGutterLine(false);
            editor1.renderer.$cursorLayer.element.style.display = "none";
        }
    };


    const handleLogout = () => {
        if (window.confirm('로그아웃 할까요??')) {
            dispatch({ type: 'logout' });
            navigate(`/`);
        }
    };

    const handleListPage = () => {
        navigate(`/list`);
    };

    const handlePageChat = () => {
        navigate(`/chat`);
    };

    
    const handleBoardPage = () => {
        navigate(`/board`);
    };

    
    const handleNoticePage = () => {
        navigate(`/notice`);
    };


    return (
        <div>
            <div className="card flex justify-content-center">
                <ButtonGroup>
                    <Button onClick={handleListPage}>강의자료</Button>&nbsp;
                    <Button onClick={handleBoardPage}>질문및답변</Button>&nbsp;
                    <Button onClick={handleNoticePage} severity="secondary">공지사항</Button>&nbsp;
                    <Button onClick={handlePageChat}>실시간메시지</Button>&nbsp;
                    <Button onClick={handleLogout}>로그아웃</Button>
                </ButtonGroup>
            </div>

            <hr />

            <div className="container">
                <div className="row">
                    <div id="editor1"></div>
                </div>
            </div>

            <footer>
                <div className="container">
                    <div className="row">
                        <p className="copyright text-muted">2016 &copy; ihongss.com</p>
                    </div>
                </div>
            </footer>

        </div>
    );
};

export default NoticePage;