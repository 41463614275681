import React from 'react';
import { useSelector } from 'react-redux';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import BoardPage from './pages/BoardPage';
import ChatPage from './pages/ChatPage';
import ListPage from './pages/ListPage';
import LoginPage from './pages/LoginPage';
import NoticePage from './pages/NoticePage';


const App = () => {


	const { logged } = useSelector((state) => state.AuthReducer);

	const router = createBrowserRouter(
		[
			{
				path: '/',
				element: <LoginPage />,
			},
			{
				path: '/chat',
				element: logged === true ? <ChatPage /> : <Navigate to="/" replace />,
			},
			{
				path: '/list',
				element: logged === true ? <ListPage /> : <Navigate to="/" replace />,
			},
			{
				path: '/board',
				element: logged === true ? <BoardPage /> : <Navigate to="/" replace />,
			},
			{
				path: '/notice',
				element: logged === true ? <NoticePage /> : <Navigate to="/" replace />,
			},
		],
		{
			future: {
				v7_relativeSplatPath: true,  // Opt-in to new relative splat path behavior
				v7_fetcherPersist: true,  // Enable new fetcher persistence behavior
				v7_normalizeFormMethod: true,  // Normalize formMethod to uppercase
				v7_skipActionErrorRevalidation: true,  // Opt-in to new action error revalidation behavior
				v7_partialHydration: true,
				v7_startTransition: true, // Opt-in to startTransition
			},
		}
	);


	return (
		<div>
			<RouterProvider router={router} future={{ v7_startTransition: true }}/>
		</div>
	);

};

export default App;